<template>
  <div>
    <!-- {{tableData[0]}} -->
    <b-table
      responsive
      :items="tableData"
      :fields="tableColumns"
      show-empty
      empty-text="Aucun resultats"
      :busy="isLoadingTableData"
      hover
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #cell(id)="data">
        <strong>{{
          (paginationData.perPage * (paginationData.currentPage - 1) || 0) +
          data.index +
          1
        }}</strong>
      </template>
      <!-- <template #cell(ref)="data">
        <b-avatar variant="dark">
          <feather-icon icon="ImageIcon" />
        </b-avatar>{{ "  " }}
        {{ data.item.ref }}
      </template> -->

      <template #cell(forms)="data">
        {{ (data && data.item.forms && data.item.forms.length) || 0 }}
      </template>

      <template #cell(actions)="data">
        <b-button
          v-if="true"
          variant="primary"
          :to="{
            name: 'commissariats-details',
            params: { agentId: data.item._id },
          }"
        >
          <feather-icon icon="EyeIcon" class="mr-1" />
          <span class="text-nowrap"> Détails</span>
        </b-button>
      </template>
    </b-table>

    <div>
      <PaginationComponent
        :table-data="tableData"
        :per-page="paginationData.perPage"
        :total-rows="paginationData.total"
        @change="onPaginationChange"
      />
    </div>
    <!-- <div
      v-if="tableData && tableData.length > 1"
      class="d-flex justify-content-between mt-1"
    >
      <div class="d-flex flex-row">
        <span class="my-auto mr-1">Afficher </span>
        <div>
          <b-form-select
            v-model="paginationData.perPage"
            :options="optionsPerPage"
            size="sm"
            class="form-control col"
          />
        </div>
        <span class="my-auto ml-1">lignes </span>
      </div>

      <div>
        <b-pagination
          v-model="paginationData.currentPage"
          :total-rows="paginationData.total"
          :per-page="paginationData.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <div class="my-auto">
        <span class="my-auto">Affichage de {{ paginationData.metaData.from }} à {{ paginationData.metaData.to }} sur {{ paginationData.total }} </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCardText,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import utilsService from "@/services/utils/utils.service";
import PaginationComponent from "@/components/PaginationComponent.vue";

// store module and vuex utilities
import etablissementsStroreModule from "@/store/etablissements";
import paramsStoreModule from "@/store/params";
import usersStoreModule from "@/store/users";
import agentGouvenementStoreModule from "@/store/agent-gouvenement";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "etablissements", module: etablissementsStroreModule },
      { path: "params", module: paramsStoreModule },
      { path: "users", module: usersStoreModule },
      { path: "agentGouvenement", module: agentGouvenementStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return { requiredStoreModules };
  },
  name: "ListAgentsComponent",
  components: {
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCardText,
    PaginationComponent,
  },

  props: { etablissementId: null },
  data() {
    return {
      form: {
        role: "",
      },

      confirmeArchiveAgentModalRef: "confirmeArchiveAgentModal",

      filters: { options: {} },
      rows: 100,
      tableData: null,
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
      tableColumns: [
        {
          key: "id",
          label: "#",
          sortable: true,
        },
        {
          key: "nom",
          label: "Nom",
          sortable: true,
        },
        {
          key: "departement",
          label: "Département",
          sortable: true,
        },
        {
          key: "commune",
          label: "Commune",
          sortable: true,
        },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        "actions",
      ],
      isLoadingTableData: false,
    };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  computed: {
    ...mapGetters("params", {
      typesRoles: "getter_typesRoles",
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0;
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(val, old) {
        // console.log('route change')
        this.getListUsers();
      },
    },
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1)
          );
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData);
        }
      },
    },
    "paginationData.currentPage": {
      handler(val, old) {
        this.getListUsers();
      },
    },
    "paginationData.perPage": {
      handler(val, old) {
        this.getListUsers();
      },
    },
  },
  methods: {
    ...mapActions("users", { action_fetchUsers: "fetchUsers" }),
    ...mapActions("params", { action_fetchTypesRoles: "fetchTypesRoles" }),
    ...mapActions("etablissements", { getAgent: "getAgent" }),
    ...mapActions("agentGouvenement", {
      createAgentGouvenement: "createAgentGouvenement",
      findAgentGouvenement: "findAgentGouvenement",
    }),
    ...mapActions("roles", {
      fetchRoles: "fetchRoles",
    }),
    // -------------------------------------------------------------------
    async getListUsers() {
      this.isLoadingTableData = true;

      const { currentUserId, userRole, userData } =
        utilsService.currentUserUtils();
  
      if (userRole.code == "commissaire") {
        this.tableData = [userData];
        this.isLoadingTableData = false;
        return;
      }

      const userRoleReq = await this.action_fetchTypesRoles().catch((err) => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message || "Une erreur est survenue",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
      if (!userRoleReq || !userRoleReq.data) {
        this.isCheckingAgentCount = false;
        this.canAddNewAgent = false;
        return;
      }

      const { resources } = userRoleReq.data;

      const currentUserRole = (resources || []).find(
        (e) => e.code === "commissaire"
      );
      const currentUserRoleId = currentUserRole._id;
      const params = {
        options: {
          role: currentUserRoleId,
          isArchive: false,
        },
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      };
      console.log("params: ⭕️", params);
      this.action_fetchUsers(params)
        .then(async (response) => {
          this.tableData = response.data.resources || [];

          // // get forms by agents
          // for (let i = 0; i < this.tableData.length; i++) {
          //   await this.getAgent(this.tableData[i]._id)
          //     .then(res => {
          //       let forms = []
          //       res.data.formsCreate.map(item => {
          //         forms = forms.concat(item.data)
          //         this.tableData[i].forms = forms
          //       })
          //     })
          //     .catch(err => { console.log(err) })
          // }

          this.paginationData.total = response.data.total || 0;
          this.isLoadingTableData = false;

          if (this.paginationData.metaData.from == 0)
            this.paginationData.metaData.from += 1;
          else
            this.paginationData.metaData.from =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage;
          this.paginationData.metaData.to =
            this.paginationData.perPage * this.paginationData.currentPage -
            this.paginationData.perPage +
            this.tableData.length;
        })
        .catch((err) => {
          this.isLoadingTableData = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });

          console.log("error-users-list", err);
        });

      // setTimeout(() => {
      //   this.tableData = new Array(200)
      //     .fill({
      //       nom: 'Nom',
      //       prenom: 'Prenom',
      //       email: 'user@user.com',
      //     })
      //     .map((e, i) => {
      //       const temp = JSON.parse(JSON.stringify(e))
      //       return { ...temp, id: i }
      //     })

      //   this.isLoadingTableData = false
      // }, 2000)
    },
    onPaginationChange(val) {
      this.paginationData.currentPage = val.currentPage;
      this.paginationData.perPage = val.perPage;
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
  a {
    text-transform: uppercase;
  }
}
.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
