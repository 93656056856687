<template>
  <div>
    <b-card class="mb-2">
      <div class="d-flex flex-wrap justify-content-between">
        <div>
          <h1
            v-if="!isCommissaire"
            class="my-auto"
          >
            Liste des commissariats
          </h1>
          <h1
            v-else
            class="my-auto"
          >
            Commissariat courant
          </h1>
        </div>

        <div>
          <b-button
            v-if="$can('create', 'commissaire')"
            class="mt-1 float-right"
            variant="primary"
            @click="$router.push({ name: 'commissariats-create' })"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>Ajouter un commissariat</span>
          </b-button>
        </div>
      </div>
    </b-card>

    <b-card>
      <ListCommissariatsComponent />
    </b-card>
    <!-- <div
      v-if="tableData && tableData.length > 1"
      class="d-flex justify-content-between mt-1"
    >
      <div class="d-flex flex-row">
        <span class="my-auto mr-1">Afficher </span>
        <div>
          <b-form-select
            v-model="paginationData.perPage"
            :options="optionsPerPage"
            size="sm"
            class="form-control col"
          />
        </div>
        <span class="my-auto ml-1">lignes </span>
      </div>

      <div>
        <b-pagination
          v-model="paginationData.currentPage"
          :total-rows="paginationData.total"
          :per-page="paginationData.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>

      <div class="my-auto">
        <span class="my-auto">Affichage de {{ paginationData.metaData.from }} à {{ paginationData.metaData.to }} sur {{ paginationData.total }} </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCard,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ListCommissariatsComponent from '@/views/apps/utilisateurs/commissariat/components/ListCommissariatsComponent.vue'
import utilsService from '@/services/utils/utils.service'

export default {
  name: 'ArticleList',
  components: {
    BFormCheckbox,
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCard,
    ListCommissariatsComponent,
  },
  data() {
    return {
      tableData: [],
      filters: { options: {} },
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      optionsPerPage: [10, 15, 30, 100, 200],
    }
  },
  computed: {
    isCommissaire() {
      const { currentUserId, userRole } = utilsService.currentUserUtils()
      return userRole.code === 'commissaire'
    },
  },
  watch: {},
  async mounted() {},
  methods: {},
}
</script>
<style lang="scss" scoped>
</style>
